<template>
  <v-window-item :value="btn">
    <v-card class="elevation-12 mx-auto pb-8">
      <nav class="d-flex justify-space-around">
        <button
          class="nav-tab w-50 py-4 text-center first-tab"
          :class="{ 'active-tab': tab === 'new' }"
          @click="go('new')"
        >
          {{ $t("New") }}
        </button>
        <button
          class="nav-tab w-50 py-4 text-center second-tab"
          :class="{ 'active-tab': tab === 'in_progress' }"
          @click="go('in_progress')"
        >
          {{ $t("In progress") }}
        </button>
        <button
          class="nav-tab w-50 py-4 text-center second-tab"
          :class="{ 'active-tab': tab === 'done' }"
          @click="go('done')"
        >
          {{ $t("Done") }}
        </button>
      </nav>
      <div class="shadow">
        <v-window v-model="tab" class="pa-4">
          <router-view> </router-view>
        </v-window>
      </div>
    </v-card>
  </v-window-item>
</template>

<script>
export default {
  name: "projects-tickets",
  props: ["btn"],
  data: () => ({
    tab: "new"
  }),
  computed: {
    activeBtn() {
      return this.btn;
    }
  },
  methods: {
    go(tab) {
      const path = `/projects/${this.btn}/${tab}`;
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    btnChange() {
      this.tab = "new";
    }
  },
  watch: {
    btn: "btnChange"
  },
  updated() {
    this.$nextTick(function() {
      const component = this.$route.name;
      if (component === "ProjectsOrdersInProgress" || component === "ProjectsBidsInProgress") {
        this.tab = "in_progress";
      } else if (component === "ProjectsOrdersNew" || component === "ProjectsBidsNew") {
        this.tab = "new";
      } else if (component === "ProjectsOrdersDone" || component === "ProjectsBidsDone") {
        this.tab = "done";
      }
    });
  }
};
</script>

<style lang="scss">
.shadow {
  -webkit-box-shadow: 0px -10px 25px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px -10px 25px -5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -10px 25px -5px rgba(0, 0, 0, 0.08);
  min-height: 200px;
}

.nav-tab {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 1.375rem;
  color: #000000;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &.active-tab {
    z-index: 1;
    color: #005fce;
    background-color: #fff;

    &.first-tab {
      -webkit-box-shadow: 10px 0 5px -5px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 10px 0 5px -5px rgba(0, 0, 0, 0.08);
      box-shadow: 10px 0 5px -5px rgba(0, 0, 0, 0.08);
    }

    &.second-tab {
      -webkit-box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.2);
      box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.2);
    }
  }
}

.inset-shadow {
  -webkit-box-shadow: inset 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
}
</style>
